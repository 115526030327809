import React from 'react'
import Header from './../components/Header'
import Sidebar from './../components/Sidebar'
import Footer from './../components/Footer'
import Breadcrumbs from './../components/Breadcrumbs'
import { onMessageListener } from './../services/firebase'
import { Toast } from 'react-bootstrap'


class LayoutComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      toastShow: false,
      toastTitle: '',
      toastContent: '',
    }
  }

  componentDidMount() {
    this.messageListener()
  }

  messageListener = () => {
    onMessageListener()
      .then((payload) => {
        this.setState({
          toastShow: payload.notification.title !== '' ? true : false,
          toastTitle: payload.notification.title,
          toastContent: payload.notification.body,
        })
      })
      .catch((err) => {
        console.log('failed: ', err)
      })
  }

  handleToastClose = () => {
    this.setState({ toastShow: false })
  }

  render() {

    return (
      <>
        <Header />
        <Sidebar />
        <div className="app-content content">
          <div className="content-overlay"></div>
          <div className="content-wrapper">
            <Breadcrumbs path={this.props.children.props.match.path} />
            <div className="content-body">
              {this.props.children}
            </div>
          </div>
        </div>
        <Footer />
        <Toast
          onClose={this.handleToastClose}
          show={this.state.toastShow}
          className="floating-toast"
        >
          <Toast.Header>
            <strong className="me-auto">{this.state.toastTitle}</strong>
          </Toast.Header>
          <Toast.Body>
            {this.state.toastContent}
          </Toast.Body>
        </Toast>
      </>
    )
  }
}

export default LayoutComponent
