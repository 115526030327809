import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  seasonPass: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  seasonUser: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  seasonSeat: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  seasonResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  seasonUserResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SAVE_LOADING:
      return Object.assign({}, state, {
        saveLoading: action.data
      })
    case constants.SET_SEASON_PASS:
      return Object.assign({}, state, {
        seasonPass: action.data
      })
    case constants.SET_SEASON_USER:
      return Object.assign({}, state, {
        seasonUser: action.data
      })
    case constants.SET_SEASON_SEAT:
      return Object.assign({}, state, {
        seasonSeat: action.data
      })
    case constants.SET_SEASON_RESPONSE:
      return Object.assign({}, state, {
        seasonResponse: action.data
      })
    case constants.SET_SEASON_USER_RESPONSE:
      return Object.assign({}, state, {
        seasonUserResponse: action.data
      })
    default: return state
  }
}
