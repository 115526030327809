import * as constants from './constants'

const INITIAL_STATE = {
  clubsLoading: false,
  saveClubsLoading: false,
  leaguesLoading: false,
  saveLeaguesLoading: false,
  clubList: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  clubDetail: {},
  clubResponse: {},
  leagueList: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  leagueDetail: {},
  leagueResponse: {},
  permissionList: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  countryList: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_CLUBS_LOADING:
      return Object.assign({}, state, {
        clubsLoading: action.data
      })
    case constants.SET_SAVE_CLUBS_LOADING:
      return Object.assign({}, state, {
        saveClubsLoading: action.data
      })
    case constants.SET_LEAGUES_LOADING:
      return Object.assign({}, state, {
        leaguesLoading: action.data
      })
    case constants.SET_SAVE_LEAGUES_LOADING:
      return Object.assign({}, state, {
        saveLeaguesLoading: action.data
      })
    case constants.SET_CLUB_LIST:
      return Object.assign({}, state, {
        clubList: action.data
      })
    case constants.SET_CLUB_DETAIL:
      return Object.assign({}, state, {
        clubDetail: action.data
      })
    case constants.SET_CLUB_RESPONSE:
      return Object.assign({}, state, {
        clubResponse: action.data
      })
    case constants.SET_LEAGUE_LIST:
      return Object.assign({}, state, {
        leagueList: action.data
      })
    case constants.SET_LEAGUE_DETAIL:
      return Object.assign({}, state, {
        leagueDetail: action.data
      })
    case constants.SET_LEAGUE_RESPONSE:
      return Object.assign({}, state, {
        leagueResponse: action.data
      })
    case constants.SET_PERMISSION_LIST:
      return Object.assign({}, state, {
        permissionList: action.data
      })
    case constants.SET_COUNTRY_LIST:
      return Object.assign({}, state, {
        countryList: action.data
      })
    default: return state
  }
}
