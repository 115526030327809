import servicesProfile from './../../services/profile'
import * as constants from './constants'

export function setLoading(data) {
  return { type: constants.SET_LOADING, data: data }
}

export function setSaveProfileLoading(data) {
  return { type: constants.SET_SAVE_PROFILE_LOADING, data: data }
}

export function setSavePasswordLoading(data) {
  return { type: constants.SET_SAVE_PASSWORD_LOADING, data: data }
}

export function setPermission(data) {
  return { type: constants.SET_PERMISSION, data: data }
}

export function setProfile(data) {
  return { type: constants.SET_PROFILE, data: data }
}

export function setProfileResponse(data) {
  return { type: constants.SET_PROFILE_RESPONSE, data: data }
}

export function getPermission() {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesProfile.getPermission()
      dispatch(setPermission(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function getProfile() {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesProfile.getProfile()
      dispatch(setProfile(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function updateProfile(payload) {
  return async dispatch => {
    dispatch(setSaveProfileLoading(true))
    try {
      const response = await servicesProfile.updateProfile(payload)
      dispatch(setProfileResponse(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setSaveProfileLoading(false))
  }
}

export function updatePassword(payload) {
  return async dispatch => {
    dispatch(setSavePasswordLoading(true))
    try {
      const response = await servicesProfile.updatePassword(payload)
      dispatch(setProfileResponse(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setSavePasswordLoading(false))
  }
}
