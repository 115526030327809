import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  leagueSponsorship: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  matchSponsorship: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  sponsorshipResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SAVE_LOADING:
      return Object.assign({}, state, {
        saveLoading: action.data
      })
    case constants.SET_LEAGUE_SPONSORSHIP:
      return Object.assign({}, state, {
        leagueSponsorship: action.data
      })
    case constants.SET_MATCH_SPONSORSHIP:
      return Object.assign({}, state, {
        matchSponsorship: action.data
      })
    case constants.SET_SPONSORSHIP_RESPONSE:
      return Object.assign({}, state, {
        sponsorshipResponse: action.data
      })
    default: return state
  }
}
