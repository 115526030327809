import servicesAuth from './../../services/auth'
import * as constants from './constants'

export function setLoading(data) {
  return { type: constants.SET_LOADING, data: data }
}

export function setChangePassword(data) {
  return { type: constants.SET_CHANGE_PASSWORD, data: data }
}

export function setForgotPassword(data) {
  return { type: constants.SET_FORGOT_PASSWORD, data: data }
}

export function setLoginOtp(data) {
  return { type: constants.SET_LOGIN_OTP, data: data }
}

export function setLoginPassword(data) {
  return { type: constants.SET_LOGIN_PASSWORD, data: data }
}

export function setLogout(data) {
  return { type: constants.SET_LOGOUT, data: data }
}

export function setRefreshToken(data) {
  return { type: constants.SET_REFRESH_TOKEN, data: data }
}

export function setVerifyAccount(data) {
  return { type: constants.SET_VERIFY_ACCOUNT, data: data }
}

export function changePassword(payload) {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.changePassword(payload)
      dispatch(setChangePassword(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function forgotPassword(payload) {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.forgotPassword(payload)
      dispatch(setForgotPassword(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function loginOtp(payload) {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.loginOtp(payload)
      dispatch(setLoginOtp(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function loginPassword(payload) {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.loginPassword(payload)
      dispatch(setLoginPassword(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function logout() {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.logout()
      dispatch(setLogout(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function refreshToken() {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.refreshToken()
      dispatch(setRefreshToken(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}

export function verifyAccount(params) {
  return async dispatch => {
    dispatch(setLoading(true))
    try {
      const response = await servicesAuth.verifyAccount(params)
      dispatch(setVerifyAccount(response))
    } catch (error) {
      console.error('error =>', error)
    }
    dispatch(setLoading(false))
  }
}
