import React from 'react'
import { setHtmlStorage } from './../../services/helper'
import history from './../../history'


class HeaderController extends React.Component {
  componentDidMount() {
    if (window.location.pathname !== '/stadium') {
      localStorage.removeItem('urlStadium')
      localStorage.removeItem('subzone_svg')
    }
    this.getProfile()
  }

  getProfile = async () => {
    await this.props.getProfile()
    if ([401, 500].includes(this.props.profile.meta.code)) {
      this.refreshToken()
    }
    if ([200, 201].includes(this.props.profile.meta.code)) {
      this.getPermission()
    }
  }

  getPermission = async () => {
    await this.props.getPermission()
    if ([200, 201].includes(this.props.permission.meta.code)) {
      const urlPath = window.location.pathname.split('/')
      const permissionPath = this.props.permission.data

      if (!this.props.profile.data.is_super_admin) {
        if (permissionPath.includes('ticket-counter')) {
          window.location = '/ticket-counter.html'
        }
      }

      if (!permissionPath.includes(urlPath[1])) {
        history.push(permissionPath[0])
      }
    }
  }

  refreshToken = async () => {
    await this.props.refreshToken()
    if ([200, 201].includes(this.props.refreshResponse.meta.code)) {
      const d = new Date(this.props.refreshResponse.data.expiry_time)
      const ms = d.getUTCMilliseconds()
      setHtmlStorage('accessToken', this.props.refreshResponse.data.access_token, ms)
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else {
      localStorage.clear()
      history.push('/login')
    }
  }

  handleLogout = async () => {
    await this.props.logout()
    localStorage.clear()
    history.push('/login')
  }
}

export default HeaderController
