import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  downloadLoading: false,
  transactions: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  downloadResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {}
  },
  // downloadSummaryLoading: false,
  transactionsSummary: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  // downloadSummaryResponse: {
  //   meta: {
  //     code: 200,
  //     message: '',
  //     errors: []
  //   },
  //   data: {}
  // },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_DOWNLOAD_LOADING:
      return Object.assign({}, state, {
        downloadLoading: action.data
      })
    case constants.SET_TRANSACTIONS:
      return Object.assign({}, state, {
        transactions: action.data
      })
    case constants.SET_DOWNLOAD_RESPONSE:
      return Object.assign({}, state, {
        downloadResponse: action.data
      })
    // case constants.SET_DOWNLOAD_SUMMARY_LOADING:
    //   return Object.assign({}, state, {
    //     downloadSummaryLoading: action.data
    //   })
    case constants.SET_TRANSACTIONS_SUMMARY:
      return Object.assign({}, state, {
        transactionsSummary: action.data
      })
    // case constants.SET_DOWNLOAD_SUMMARY_RESPONSE:
    //   return Object.assign({}, state, {
    //     downloadSummaryResponse: action.data
    //   })
    default: return state
  }
}
