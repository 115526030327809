import React from 'react'
import { connect } from 'react-redux'

class FooterComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
    }
  }

  render() {
    return (
      <footer className="footer footer-static footer-dark">
        <p className="clearfix mb-0">
          <span className="float-left d-inline-block">
            2022 &copy; FISB
          </span>
          <span className="float-right d-sm-inline-block d-none">
            Crafted with<i className="bx bxs-heart pink mx-50 font-small-3"></i>by<a className="text-uppercase" href="/" target="_blank" rel="noopener noreferrer">FISB</a>
          </span>
          {/* <button className="btn btn-primary btn-icon scroll-top" type="button">
            <i className="bx bx-up-arrow-alt"></i>
          </button> */}
        </p>
      </footer>
    )
  }
}

const mapState = state => {
  return {}
}

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(FooterComponent)
