import { createStore, combineReducers, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'

import activityReducers from './activityLog/reducers'
import adminReducers from './admin/reducers'
import authReducers from './auth/reducers'
import calendarReducers from './calendar/reducers'
import customerReducers from './customer/reducers'
import dashboardReducers from './dashboard/reducers'
import globalReducers from './global/reducers'
import masterReducers from './master/reducers'
import paymentsReducers from './payments/reducers'
import profileReducers from './profile/reducers'
import roleReducers from './userRole/reducers'
import salesReducers from './salesReport/reducers'
import seasonReducers from './seasonPass/reducers'
import stadiumReducers from './stadium/reducers'
import sponsorReducers from './sponsors/reducers'
import sponsorshipReducers from './sponsorship/reducers'
import sponsorTypeReducers from './sponsorsType/reducers'
import ticketManageReducers from './ticketManagement/reducers'
import transactionReducers from './transactionHistory/reducers'
import uploadReducers from './upload/reducers'
import zoneManageReducers from './zoneManagement/reducers'

const rootReducer = {
  activityReducers: activityReducers,
  adminReducers: adminReducers,
  authReducers: authReducers,
  calendarReducers: calendarReducers,
  customerReducers: customerReducers,
  dashboardReducers: dashboardReducers,
  globalReducers: globalReducers,
  masterReducers: masterReducers,
  paymentsReducers: paymentsReducers,
  profileReducers: profileReducers,
  roleReducers: roleReducers,
  salesReducers: salesReducers,
  seasonReducers: seasonReducers,
  stadiumReducers: stadiumReducers,
  sponsorReducers: sponsorReducers,
  sponsorshipReducers: sponsorshipReducers,
  sponsorTypeReducers: sponsorTypeReducers,
  ticketManageReducers: ticketManageReducers,
  transactionReducers: transactionReducers,
  uploadReducers: uploadReducers,
  zoneManageReducers: zoneManageReducers,
}

const reducer = combineReducers(rootReducer)
export const store = createStore(reducer, applyMiddleware(thunkMiddleware))
