import api from './api'

export default {
  changePassword: payload => api.post('admin/api/v1/auth/change_password', payload),
  forgotPassword: payload => api.post('admin/api/v1/auth/forgot_password', payload),
  loginOtp: payload => api.post('admin/api/v1/auth/login_otp', payload),
  loginPassword: payload => api.post('admin/api/v1/auth/login_password', payload),
  logout: () => api.post('admin/api/v1/auth/logout'),
  refreshToken: () => api.post('admin/api/v1/auth/refresh_token'),
  verifyAccount: params => api.get(`admin/api/v1/auth/verify_account${params}`),
}
