import React from 'react'
import { connect } from 'react-redux'
import { Dropdown, Image, Nav, Navbar } from 'react-bootstrap'
import Controller from './controller'
import avatar from './../../assets/avatar-s-11.jpg'
import * as actionAuth from './../../store/auth/actions'
import * as actionProfile from './../../store/profile/actions'


class HeaderComponent extends Controller {
  render() {
    return (
      <>
        <div className="header-navbar-shadow"></div>
        <Navbar
          expand="lg"
          variant="dark"
          className="header-navbar main-header-navbar navbar-with-menu fixed-top"
          style={{
            backgroundColor: 'rgb(39, 46, 72)',
            boxShadow: 'rgb(26 35 59 / 70%) -8px 12px 18px 0px'
          }}
        >
          <div className="navbar-wrapper">
            <div className="navbar-container content">
              <Navbar.Collapse id="navbar-mobile">
                <div className="mr-auto float-left bookmark-wrapper d-flex align-items-center">
                  <Nav as="ul">
                    <Nav.Item as="li" className="mobile-menu d-xl-none mr-auto">
                      <Nav.Link className="nav-menu-main menu-toggle hidden-xs" href="/">
                        <i className="ficon bx bx-menu"></i>
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
                <Nav as="ul" className="float-right nav">
                  <Dropdown as="li" className="dropdown-user">
                    <Dropdown.Toggle as="a" className="nav-link dropdown-user-link" style={{ cursor: 'pointer' }}>
                      <div className="user-nav d-sm-flex d-none">
                        <span className="user-name">{this.props.profile.data.name}</span>
                        <span className="user-status text-muted">{this.props.profile.data.title}</span>
                      </div>
                      <span>
                        <Image src={avatar} roundedCircle width={40} height={40} />
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu align="end">
                      <Dropdown.Item href="/profile">
                        <i className="bx bx-user mr-50"></i> Edit Profile
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <Dropdown.Item href="#" onClick={this.handleLogout}>
                        <i className="bx bx-power-off mr-50"></i> Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Nav>
              </Navbar.Collapse>
            </div>
          </div>
        </Navbar>
      </>
    )
  }
}

const mapState = state => {
  const { authReducers, profileReducers } = state
  return {
    logoutResponse: authReducers.logoutResponse,
    refreshResponse: authReducers.refreshResponse,
    profile: profileReducers.profile,
    permission: profileReducers.permission,
  }
}

const mapDispatch = dispatch => ({
  logout: () => dispatch(actionAuth.logout()),
  refreshToken: () => dispatch(actionAuth.refreshToken()),
  getProfile: () => dispatch(actionProfile.getProfile()),
  getPermission: () => dispatch(actionProfile.getPermission()),
})

export default connect(mapState, mapDispatch)(HeaderComponent)
