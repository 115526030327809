import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  imageResponse: {
    code: 0,
    data: {
      accessUrl: '',
      expiredAt: null,
      fullUrl: '',
      key: '',
      keyCDN: '',
    },
    message: '',
    status: ''
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_IMAGE_RESPONSE:
      return Object.assign({}, state, {
        imageResponse: action.data
      })
    default: return state
  }
}
