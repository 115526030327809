import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  changePassResponse: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  forgotPassResponse: {},
  loginOtpResponse: {},
  loginPassResponse: {},
  logoutResponse: {},
  refreshResponse: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: {
      access_token: '',
      expiry_time: '',
    }
  },
  verifyAccResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_CHANGE_PASSWORD:
      return Object.assign({}, state, {
        changePassResponse: action.data
      })
    case constants.SET_FORGOT_PASSWORD:
      return Object.assign({}, state, {
        forgotPassResponse: action.data
      })
    case constants.SET_LOGIN_OTP:
      return Object.assign({}, state, {
        loginOtpResponse: action.data
      })
    case constants.SET_LOGIN_PASSWORD:
      return Object.assign({}, state, {
        loginPassResponse: action.data
      })
    case constants.SET_LOGOUT:
      return Object.assign({}, state, {
        logoutResponse: action.data
      })
    case constants.SET_REFRESH_TOKEN:
      return Object.assign({}, state, {
        refreshResponse: action.data
      })
    case constants.SET_VERIFY_ACCOUNT:
      return Object.assign({}, state, {
        verifyAccResponse: action.data
      })
    default: return state
  }
}
