import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  profileLoading: false,
  passwordLoading: false,
  permission: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: ['Dashboard']
  },
  profile: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: {
      id: '',
      name: '',
      email: '',
      password: '',
      phone_number: '',
      title: '',
      is_super_admin: false,
      stadium: { id: '', name: '' },
      email_verification_time: '',
      phone_number_verification_time: null,
      is_active: false,
      permissions: [],
      created_by: { id: '', email: '' },
      updated_by: { id: '', email: '' },
      created_at: '',
      updated_at: ''
    }
  },
  profileResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SAVE_PROFILE_LOADING:
      return Object.assign({}, state, {
        profileLoading: action.data
      })
    case constants.SET_SAVE_PASSWORD_LOADING:
      return Object.assign({}, state, {
        passwordLoading: action.data
      })
    case constants.SET_PERMISSION:
      let newData = action.data
      for (let i = 0; i < newData.data.length; i++) {
        let data = newData.data[i]
        data = data.toLowerCase()
        newData.data[i] = data.split(' ').join('-')
      }
      newData.data.push('profile')
      return Object.assign({}, state, {
        permission: newData
      })
    case constants.SET_PROFILE:
      return Object.assign({}, state, {
        profile: action.data
      })
    case constants.SET_PROFILE_RESPONSE:
      return Object.assign({}, state, {
        profileResponse: action.data
      })
    default: return state
  }
}
