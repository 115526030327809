import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  profiles: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  profileDetail: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: {},
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  profileResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: {},
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  subzoneResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: {},
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  subzoneStatus: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: {},
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  seatResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: {},
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  syncResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: {},
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SAVE_LOADING:
      return Object.assign({}, state, {
        saveLoading: action.data
      })
    case constants.SET_PROFILE:
      return Object.assign({}, state, {
        profiles: action.data
      })
    case constants.SET_PROFILE_DETAIL:
      return Object.assign({}, state, {
        profileDetail: action.data
      })
    case constants.SET_PROFILE_RESPONSE:
      return Object.assign({}, state, {
        profileResponse: action.data
      })
    case constants.SET_SUBZONE_RESPONSE:
      return Object.assign({}, state, {
        subzoneResponse: action.data
      })
    case constants.SET_SUBZONE_STATUS:
      return Object.assign({}, state, {
        subzoneStatus: action.data
      })
    case constants.SET_SEAT_RESPONSE:
      return Object.assign({}, state, {
        seatResponse: action.data
      })
    case constants.SET_SYNC_RESPONSE:
      return Object.assign({}, state, {
        syncResponse: action.data
      })
    default: return state
  }
}
