import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  calendar: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  matches: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  matchResponse: {},
  colorResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SAVE_LOADING:
      return Object.assign({}, state, {
        saveLoading: action.data
      })
    case constants.SET_CALENDAR:
      return Object.assign({}, state, {
        calendar: action.data
      })
    case constants.SET_MATCHES:
      return Object.assign({}, state, {
        matches: action.data
      })
    case constants.SET_MATCH:
      return Object.assign({}, state, {
        detailMatch: action.data
      })
    case constants.SET_MATCHES_RESPONSE:
      return Object.assign({}, state, {
        matchResponse: action.data
      })
    case constants.SET_COLOR_RESPONSE:
      return Object.assign({}, state, {
        colorResponse: action.data
      })
    default: return state
  }
}
