import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  sponsorType: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SPONSOR_TYPE:
      return Object.assign({}, state, {
        sponsorType: action.data
      })
    default: return state
  }
}
