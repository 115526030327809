import React from 'react'
import { Link } from 'react-router-dom'



class BreadcrumbComponent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      path: this.props.path,
      matchManagement: [
        'stadium',
        'match-calendar',
        'football-club',
        'sponsor',
        'ticket-counter',
        'ticket-management',
        'season-pass',
        'zone-management',
      ],
      reporting: [
        'sales-report',
        'transaction-history',
      ],
      invoice: ['invoice'],
      userManagement: [
        'customers-list',
        'admin',
        'admin-activity-log',
      ],
    }
  }

  render() {
    const path = this.state.path.split('/')
    let title = ''
    let itemTitle = ''

    if (path[1] === 'dashboard') {
      return <></>
    }

    if (this.state.matchManagement.includes(path[1])) {
      title = 'Match Management'
    }
    if (this.state.reporting.includes(path[1])) {
      title = 'Reporting'
    }
    if (this.state.invoice.includes(path[1])) {
      title = 'Invoice'
    }
    if (this.state.userManagement.includes(path[1])) {
      title = 'User Management'
    }
    if (path[1] === 'profile') {
      title = 'Profile'
    }

    if (path[1].includes('-')) {
      itemTitle = path[1].split('-')
      for (let i = 0; i < itemTitle.length; i++) {
        itemTitle[i] = itemTitle[i][0].toUpperCase() + itemTitle[i].slice(1)
      }
      itemTitle = itemTitle.join(' ')
    } else {
      itemTitle = path[1].charAt(0).toUpperCase() + path[1].slice(1)
    }

    return (
      <div className="content-header">
        <div className="content-header-left mb-2 mt-1">
          <div className="breadcrumbs-top">
            <h5 className="content-header-title float-left pr-1 mb-0">{title}</h5>
            <div className="breadcrumb-wrapper">
              <ol className="breadcrumb p-0 mb-0">
                <li className="breadcrumb-item">
                  <Link to="/"><i className="bx bx-home-alt"></i></Link>
                </li>
                {itemTitle !== '' && <li className="breadcrumb-item">{itemTitle}</li>}
              </ol>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default BreadcrumbComponent
