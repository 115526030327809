import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import Async from 'react-code-splitting'
import layout from './layout'

const dashboard = props => <Async load={import('./../pages/dashboard')} componentProps={props} />
const profile = props => <Async load={import('./../pages/profile')} componentProps={props} />
const stadium = props => <Async load={import('./../pages/stadium')} componentProps={props} />
const calendar = props => <Async load={import('./../pages/calendar')} componentProps={props} />
const addCalendar = props => <Async load={import('./../pages/calendar/add')} componentProps={props} />
const clubLeague = props => <Async load={import('./../pages/clubLeague')} componentProps={props} />
const sponsors = props => <Async load={import('./../pages/sponsors')} componentProps={props} />
const ticketManagment = props => <Async load={import('./../pages/ticketManagement')} componentProps={props} />
const seasonPass = props => <Async load={import('./../pages/seasonPass')} componentProps={props} />
const zoneManagement = props => <Async load={import('./../pages/zoneManagement')} componentProps={props} />
const salesReport = props => <Async load={import('./../pages/salesReport')} componentProps={props} />
const transactionHistory = props => <Async load={import('./../pages/transactionHistory')} componentProps={props} />
const invoice = props => <Async load={import('./../pages/invoice')} componentProps={props} />
const customers = props => <Async load={import('./../pages/customers')} componentProps={props} />
const admin = props => <Async load={import('./../pages/admin')} componentProps={props} />
const adminActivityLog = props => <Async load={import('./../pages/adminActivityLog')} componentProps={props} />
const login = props => <Async load={import('./../pages/login')} componentProps={props} />
const forgot = props => <Async load={import('./../pages/forgot')} componentProps={props} />
const changePassword = props => <Async load={import('./../pages/changePassword')} componentProps={props} />
const verifyAdmin = props => <Async load={import('./../pages/admin/verify')} componentProps={props} />


class Routes extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/dashboard" component={layout(dashboard)} />
        <Route exact path="/profile" component={layout(profile)} />
        <Route exact path="/stadium" component={layout(stadium)} />
        <Route exact path="/match-calendar" component={layout(calendar)} />
        <Route exact path="/match-calendar/add" component={layout(addCalendar)} />
        <Route exact path="/football-club" component={layout(clubLeague)} />
        <Route exact path="/sponsor" component={layout(sponsors)} />
        <Route exact path="/ticket-management" component={layout(ticketManagment)} />
        <Route exact path="/season-pass" component={layout(seasonPass)} />
        <Route exact path="/zone-management" component={layout(zoneManagement)} />
        <Route exact path="/sales-report" component={layout(salesReport)} />
        <Route exact path="/transaction-history" component={layout(transactionHistory)} />
        <Route exact path="/invoice" component={layout(invoice)} />
        <Route exact path="/customers-list" component={layout(customers)} />
        <Route exact path="/admin" component={layout(admin)} />
        <Route exact path="/admin-activity-log" component={layout(adminActivityLog)} />
        <Route exact path="/login" component={login} />
        <Route exact path="/forgot-password" component={forgot} />
        <Route exact path="/change-password" component={changePassword} />
        <Route exact path="/verify-account/:id" component={verifyAdmin} />
        <Redirect from="/" to="/login" />
      </Switch>
    )
  }
}

const mapState = state => {
  return {}
}

const mapDispatch = dispatch => ({
})

export default connect(mapState, mapDispatch)(Routes)
