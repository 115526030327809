import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  customers: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  customersResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_CUSTOMERS:
      return Object.assign({}, state, {
        customers: action.data
      })
    case constants.SET_CUSTOMERS_RESPONSE:
      return Object.assign({}, state, {
        customersResponse: action.data
      })
    default: return state
  }
}
