import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  payments: {},
  gateways: {}
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_PAYMENTS:
      return Object.assign({}, state, {
        payments: action.data
      })
    case constants.SET_GATEWAYS:
      return Object.assign({}, state, {
        gateways: action.data
      })
    default: return state
  }
}
