export const SET_CLUBS_LOADING = 'src/store/master/SET_CLUBS_LOADING'
export const SET_SAVE_CLUBS_LOADING = 'src/store/master/SET_SAVE_CLUBS_LOADING'
export const SET_LEAGUES_LOADING = 'src/store/master/SET_LEAGUES_LOADING'
export const SET_SAVE_LEAGUES_LOADING = 'src/store/master/SET_SAVE_LEAGUES_LOADING'
export const SET_CLUB_LIST = 'src/store/master/SET_CLUB_LIST'
export const SET_CLUB_DETAIL = 'src/store/master/SET_CLUB_DETAIL'
export const SET_CLUB_RESPONSE = 'src/store/master/SET_CLUB_RESPONSE'
export const SET_LEAGUE_LIST = 'src/store/master/SET_LEAGUE_LIST'
export const SET_LEAGUE_DETAIL = 'src/store/master/SET_LEAGUE_DETAIL'
export const SET_LEAGUE_RESPONSE = 'src/store/master/SET_LEAGUE_RESPONSE'
export const SET_PERMISSION_LIST = 'src/store/master/SET_PERMISSION_LIST'
export const SET_COUNTRY_LIST = 'src/store/master/SET_COUNTRY_LIST'
