import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  roles: {},
  rolesResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_USER_ROLE:
      return Object.assign({}, state, {
        roles: action.data
      })
    case constants.SET_USER_ROLE_RESPONSE:
      return Object.assign({}, state, {
        rolesResponse: action.data
      })
    default: return state
  }
}
