import { initializeApp } from 'firebase/app'
import { getMessaging, getToken, onMessage } from 'firebase/messaging'



// Replace this firebaseConfig object with the congurations for the project you created on your firebase console.
const firebaseConfig = {
  apiKey: "AIzaSyDySUmzQeaUttq7X_HxVs4JedDVEQkCqJc",
  authDomain: "ticket-hotline.firebaseapp.com",
  projectId: "ticket-hotline",
  storageBucket: "ticket-hotline.appspot.com",
  messagingSenderId: "503694972819",
  appId: "1:503694972819:web:0e8ea8853bb75ddb367a66",
  measurementId: "G-19L71P7FQJ"
}

initializeApp(firebaseConfig)

const messaging = getMessaging()

export const requestForToken = () => {
  return getToken(messaging, { vapidKey: 'BOtZ_2g0j9J5vRa8Gzbl-TIwbreViBFqObHo9wNn8SeNjBgXbXTVBKEANsXkZBbIhdhPsFEzWoC950XNHzSftGw' })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken
      }
    })
    .catch((err) => {
      console.log('An error occurred while retrieving token. ', err)
    })
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log('payload', payload)
      resolve(payload)
    })
  })

