import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Image, Nav } from 'react-bootstrap'
import logo from './../../assets/logo.png'

class Sidebar extends React.Component {
  render() {
    return (
      <div className="main-menu menu-fixed menu-dark menu-accordion menu-shadow">
        <div className="navbar-header">
          <Nav className="navbar-nav flex-row">
            <Nav.Item className="mr-auto">
              <Link className="navbar-brand" to="/">
                <span className="brand-logo">
                  <Image className="logo" src={logo} />
                </span>
              </Link>
            </Nav.Item>
            <Nav.Item className="nav-toggle">
              <Nav.Link href="#" className="modern-nav-toggle pr-0">
                <i className="bx bx-x d-block d-xl-none font-medium-4 primary"></i>
                <i className="toggle-icon bx bx-disc font-medium-4 d-none d-xl-block primary"></i>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="shadow-bottom"></div>
        <div className="main-menu-content">
          <ul id="main-menu-navigation" className="navigation navigation-main">
            {this.props.permission.data.includes('dashboard') && (
              <Nav.Item as="li">
                <Link to="/dashboard">
                  <i className="bx bx-desktop"></i>
                  <span className="menu-title">Dashboard</span>
                </Link>
              </Nav.Item>
            )}
            <ul>
              <li className="navigation-header">
                <span>Match Management</span>
              </li>
              {this.props.permission.data.includes('stadium') && (
                <Nav.Item as="li">
                  <Link to="/stadium">
                    <i className="bx bx-trophy"></i>
                    <span className="menu-title">Stadium</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('match-calendar') && (
                <Nav.Item as="li">
                  <Link to="/match-calendar">
                    <i className="bx bx-calendar"></i>
                    <span className="menu-title">Match Calendar</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('football-club') && (
                <Nav.Item as="li">
                  <Link to="/football-club">
                    <i className="bx bx-football"></i>
                    <span className="menu-title">Football Club & League</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('sponsor') && (
                <Nav.Item as="li">
                  <Link to="/sponsor">
                    <i className="bx bx-card"></i>
                    <span className="menu-title">Sponsors</span>
                  </Link>
                </Nav.Item>
              )}
              {!this.props.profile.data.is_super_admin && this.props.permission.data.includes('ticket-counter') && (
                <Nav.Item as="li">
                  <Link to="/ticket-counter.html">
                    <i className="bx bx-memory-card"></i>
                    <span className="menu-title">Ticket Counter</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('ticket-management') && (
                <Nav.Item as="li">
                  <Link to="/ticket-management">
                    <i className="bx bx-memory-card"></i>
                    <span className="menu-title">Ticket Management</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('season-pass') && (
                <Nav.Item as="li">
                  <Link to="/season-pass">
                    <i className="bx bx-memory-card"></i>
                    <span className="menu-title">Season Pass</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('zone-management') && (
                <Nav.Item as="li">
                  <Link to="/zone-management">
                    <i className="bx bx-area"></i>
                    <span className="menu-title">Zone Management</span>
                  </Link>
                </Nav.Item>
              )}
            </ul>
            <ul>
              <li className="navigation-header">
                <span>Reporting</span>
              </li>
              {this.props.permission.data.includes('sales-report') && (
                <Nav.Item as="li">
                  <Link to="/sales-report">
                    <i className="bx bx-bar-chart-square"></i>
                    <span className="menu-title">Sales Report</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('transaction-history') && (
                <Nav.Item as="li">
                  <Link to="/transaction-history">
                    <i className="bx bx-bar-chart-square"></i>
                    <span className="menu-title">Transaction History</span>
                  </Link>
                </Nav.Item>
              )}
            </ul>
            <ul>
              <li className="navigation-header">
                <span>Invoice</span>
              </li>
              {this.props.permission.data.includes('invoice') && (
                <Nav.Item as="li">
                  <Link to="/invoice">
                    <i className="bx bx-file"></i>
                    <span className="menu-title">Invoice List</span>
                  </Link>
                </Nav.Item>
              )}
            </ul>
            <ul>
              <li className="navigation-header">
                <span>User Management</span>
              </li>
              {this.props.permission.data.includes('customers-list') && (
                <Nav.Item as="li">
                  <Link to="/customers-list">
                    <i className="bx bx-group"></i>
                    <span className="menu-title">Customers List</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('admin') && (
                <Nav.Item as="li">
                  <Link to="/admin">
                    <i className="bx bx-user"></i>
                    <span className="menu-title">Admin</span>
                  </Link>
                </Nav.Item>
              )}
              {this.props.permission.data.includes('admin-activity-log') && (
                <Nav.Item as="li">
                  <Link to="/admin-activity-log">
                    <i className="bx bx-id-card"></i>
                    <span className="menu-title">Admin Activity Log</span>
                  </Link>
                </Nav.Item>
              )}
            </ul>
          </ul>
        </div>
      </div>
    )
  }
}

const mapState = state => {
  const { profileReducers } = state
  return {
    profile: profileReducers.profile,
    permission: profileReducers.permission
  }
}

export default connect(mapState)(Sidebar)
