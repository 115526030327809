import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  saveLoading: false,
  matchTicket: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  subzones: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  seats: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  ticketResponse: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {}
  },
  ticketDownload: {
    meta: {
      code: 200,
      message: '',
      errors: []
    },
    data: {}
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_SAVE_LOADING:
      return Object.assign({}, state, {
        saveLoading: action.data
      })
    case constants.SET_MATCH_TICKET:
      return Object.assign({}, state, {
        matchTicket: action.data
      })
    case constants.SET_SUBZONE:
      return Object.assign({}, state, {
        subzones: action.data
      })
    case constants.SET_SEAT:
      return Object.assign({}, state, {
        seats: action.data
      })
    case constants.SET_TICKET_RESPONSE:
      return Object.assign({}, state, {
        ticketResponse: action.data
      })
    case constants.SET_TICKET_DOWNLOAD:
      return Object.assign({}, state, {
        ticketDownload: action.data
      })
    default: return state
  }
}
