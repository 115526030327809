import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  adminResponse: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  adminDetail: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
  adminList: {
    meta: {
      code: 500,
      message: '',
      errors: []
    },
    data: {
      data: [],
      pagination: {
        page: 1,
        limit: 10,
        total_page: 1,
        total_data: 1
      }
    }
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_ADMIN_RESPONSE:
      return Object.assign({}, state, {
        adminResponse: action.data
      })
    case constants.SET_ADMIN_DETAIL:
      return Object.assign({}, state, {
        adminDetail: action.data
      })
    case constants.SET_ADMIN_LIST:
      return Object.assign({}, state, {
        adminList: action.data
      })
    default: return state
  }
}
