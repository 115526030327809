import * as constants from './constants'

const INITIAL_STATE = {
  loading: false,
  downloadLoading: false,
  transaction: {},
  count: {},
  statistic: {},
  match: {},
  stadium: {},
  downloadResponse: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case constants.SET_LOADING:
      return Object.assign({}, state, {
        loading: action.data
      })
    case constants.SET_DOWNLOAD_LOADING:
      return Object.assign({}, state, {
        downloadLoading: action.data
      })
    case constants.SET_TRANSACTION:
      return Object.assign({}, state, {
        transaction: action.data
      })
    case constants.SET_COUNT:
      return Object.assign({}, state, {
        count: action.data
      })
    case constants.SET_STATISTIC:
      return Object.assign({}, state, {
        statistic: action.data
      })
    case constants.SET_MATCH:
      return Object.assign({}, state, {
        match: action.data
      })
    case constants.SET_STADIUM:
      return Object.assign({}, state, {
        stadium: action.data
      })
    case constants.SET_DOWNLOAD_RESPONSE:
      return Object.assign({}, state, {
        downloadResponse: action.data
      })
    default: return state
  }
}
