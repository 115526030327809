export default {
  get: async (url, customHeaders = {}) => {
    const token = localStorage.getItem('accessToken')
    if (token === null && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location = '/login'
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + url, {
        method: 'GET',
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...customHeaders
        }
      })
      return await response.json()
    } catch (error) {
      console.error(error)
    }
  },

  post: async (url, bodyData = {}, customHeaders = {}) => {
    const token = localStorage.getItem('accessToken')
    if (token === null && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location = '/login'
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + url, {
        method: 'POST',
        timeout: 120000,
        body: JSON.stringify(bodyData),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...customHeaders
        }
      })
      return await response.json()
    } catch (error) {
      console.error(error)
    }
  },

  postData: async (url, bodyData = {}, customHeaders = {}) => {
    const token = localStorage.getItem('accessToken')
    if (token === null && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location = '/login'
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + url, {
        method: 'POST',
        timeout: 120000,
        body: bodyData,
        headers: {
          'Authorization': `Bearer ${token}`,
          ...customHeaders
        }
      })
      return await response.json()
    } catch (error) {
      console.error(error)
    }
  },

  patch: async (url, bodyData = {}, customHeaders = {}) => {
    const token = localStorage.getItem('accessToken')
    if (token === null && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location = '/login'
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + url, {
        method: 'PATCH',
        timeout: 120000,
        body: JSON.stringify(bodyData),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...customHeaders
        }
      })
      return await response.json()
    } catch (error) {
      console.error(error)
    }
  },

  download: async (url, customHeaders = {}) => {
    const token = localStorage.getItem('accessToken')
    if (token === null && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location = '/login'
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + url, {
        method: 'GET',
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...customHeaders
        }
      })
      return await response.blob()
    } catch (error) {
      console.error(error)
    }
  },

  delete: async (url, customHeaders = {}) => {
    const token = localStorage.getItem('accessToken')
    if (token === null && window.location.pathname !== '/login') {
      localStorage.clear()
      window.location = '/login'
    }
    try {
      const response = await fetch(process.env.REACT_APP_API_URI + url, {
        method: 'DELETE',
        timeout: 120000,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          ...customHeaders
        }
      })
      return await response.json()
    } catch (error) {
      console.error(error)
    }
  }
}
